<template>
  <div style="margin-top: 2rem;">
    <div style="width:100%;height:3.4rem;">
      <div style="bottom: 0.1rem;font-size: 1.5rem;margin: 0;
          margin-left: 1rem; line-height: 1.5rem;font-weight: 600;
           display: flex;
           justify-content: center;
           color:#278AFB">
        <span style="font-size: 5rem;font-weight: bold;
        margin-top: -1rem;
        font-style: italic">
          {{ imgsrc }}</span>
        <span style="margin-left: 0.5rem">{{title}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'lettertitle',
  data() {
    return {
    };
  },
  props: ['title', 'imgsrc'],
};
</script>

<style scoped>

</style>
