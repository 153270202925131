<template>
  <div>
    <leaflet-panel
        :body="body"
        :image="image"
        :show-buy-btn="showBuyBtn"
        :show-detail-btn="showDetailBtn"
        :title="title"
    />
<!--    <ImageList :images="images"></ImageList>-->
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/map.svg`)"
               :title="`产品优势`" :en_title="`Product Description`"></Paragraph>

    <Contentlist :datalist="datalist1"></Contentlist>

    <Paragraph :imgurl="require(`@/assets/image/contentsvg/king.svg`)"
               :title="`产品能力`" :en_title="`Product Capabilities`"></Paragraph>

    <Contentlist :datalist="datalist2"></Contentlist>

    <Paragraph :imgurl="require(`@/assets/image/contentsvg/db.svg`)"
               :title="`产品特点`" :en_title="`Features`"></Paragraph>

    <Contentlist :datalist="datalist3"></Contentlist>

    <Paragraph :imgurl="require(`@/assets/image/contentsvg/note.svg`)"
               :title="`应用场景`" :en_title="`Applocation Scenarjos`"></Paragraph>
    <div style="display: flex;flex-direction: row;margin-left: 10%;margin-right: 10%">
      <Lettertitleblock :datalist="datalistx"
                        style="display: flex;flex-direction: row;justify-content:center ;
                        width: 100%"></Lettertitleblock>
    </div>
  </div>
</template>

<script>
import LeafletPanel from '@/components/common/LeafletPanel';
// import ImageList from '@/components/common/ImageList';
import Paragraph from '@/components/chain/basic/common/paragraph';
import Contentlist from '@/components/chain/basic/common/contentlist';
import Lettertitleblock from '@/components/chain/basic/common/lettertitleblock';

export default {
  name: 'BaaSLeaflet',
  components: {
    LeafletPanel,
    // ImageList,
    Paragraph,
    Contentlist,
    Lettertitleblock,
  },
  data() {
    return {
      datalistx: [
        {
          title: '行业和客户痛点',
          imgsrc: 'A',
          list: [
            '营销行业因为数据安全等原因数据无法安全的进行共享',
            '营销需求方无法“真正触达”营销客户',
            '营销活动的未能实现用户获取价值最大化',
          ],
        }, {
          title: '解决痛点',
          imgsrc: 'B',
          list: [
            '蚂蚁链一体机可以让用户的程序运行在',
            '由安全计算硬件提供的安全环境里',
            '保障用户的程序无后门，隐私数据不被泄露',
          ],
        }, {
          title: '客户价值',
          imgsrc: 'C',
          list: [
            '基于蚂蚁链一体机的联合营销平台，',
            '通过一体机提供的安全计算硬件，基于各方的加密数据，',
            '实现用户数据的交叉融合计算，在保证数据隐私安全',
            '不被滥用的情况下，使得营销企业触达营销活动的真实用户数据',
          ],
        },
      ],
      datalist3: [{
        title: '1）自主可按、软硬件双重安全',
        list: [
          '集成国密三级密码卡等多个自研安全硬件设备，并融合自研 BaaS 平台，实现软件加硬件双重安全保障，自主可控。目前，蚂蚁链连续四年位居全球区块链专利申请数第一。',
        ],
      }, {
        title: '2）软硬一体化交付和运维',
        list: [
          '交付标准化、开箱即用，“分钟级”快速完成部署，节省90%以上的部署时间。降低启动成本，快速投入业务，加速场景落地。',
        ],
      }, {
        title: '3）国产自研一体机支持双芯片架构',
        list: [
          '国产自研一体支持两种芯片架构，可根据需求进行硬件及软件的选配，满足国产化等不同场景。',
        ],
      }, {
        title: '4）性能卓越、稳定性高',
        list: [
          '预装区块链软件，出厂前完成专业测试及性能调优，无兼容性问题，高性能高可靠，性能最高可提升 5-10 倍，网络共识最高可降低共识延迟 40%。',
        ],
      },
      ],
      datalist2: [{
        title: '1）安全自主可控服务器',
        list: [
          '提供两种CPU型号的服务器配置，并集成蚂蚁链专为区块链设计定制安全硬件，包括国密三级密码卡，安全计算硬件，网络共识提速器，自主原创、更安全高效。',
        ],
      }, {
        title: '2）高性能高可靠BaaS平台',
        list: [
          'BaaS 平台依托蚂蚁集团支付宝的海量并发技术经验，交易支持秒级确认。提供海量数据存储，具备万级 TPS 的处理能力。目前已落地超过 50 个区块链应用场景，全方位覆盖金融、政府、银行、医疗、生活、零售、通用服务等众多行业。',
        ],
      }, {
        title: '3）“0”代码存证及极速智能合约',
        list: [
          '提供图形化及 OPENAPI 方式的存证，用户无需开发，“0” 代码进行区块链存证；用户可基于合约模板仅需少量代码，快速开发具备智能合约应用。',
        ],
      },
      ],
      datalist1: [{
        title: '1）自主研发区块链密码卡',
        list: [
          '拥有国密三级认证资质，为节点提供高级别安全和可靠密钥管理，并通过硬件加速提高密码算法效率（加速5-10倍）。',
        ],
      }, {
        title: '2）自主研发区块链网络共识加速器',
        list: [
          '自主研发高性能硬件共识加速处理器，通过智能硬件加速共识算法及网络通讯性能，并提高可扩展性，典型场景中可降低共识延迟40%。',
        ],
      }, {
        title: '3）自主研发区块链安全计算硬件',
        list: [
          '区块链隐私安全硬件解决方案。通过高性能加解密引擎和隔离计算，实现链上数据全生命周期的隐私保护。该安全计算硬件具有完整的信任根部署流程，完善的信任链建立和证明协议，并通过高性能多路并发智能合约调度处理实现安全和性能双重保障。',
        ],
      }, {
        title: '4）自主可控BaaS平台',
        list: [
          '自主研发的BaaS 平台具备高性能、强隐私保护的区块链技术平台，蚂蚁链团队在共识性能、隐私保护，节点全球部署等关键技术研发上均有突破。连续四年位居全球区块链专利申请数第一。',
        ],
      },
      ],
      title: '一体机服务',
      body:
          '蚂蚁链一体机是深度融合软硬件技术、针对区块链技术特色打造的软硬一体化服务器。面对区块链技术落地中的性能、'
          + '安全和隐私等技术挑战，结合自主硬件技术，打造了高性能、强隐私和高安全的软硬件一体化服务器，提供开箱即用、快速部署落地的交付方式。',
      image: require('@/assets/image/chain/basic/aio/aio.svg'),
      showDetailBtn: true,
      showBuyBtn: true,
      images: [
        require('@/assets/image/chain/basic/aio/16_01.jpg'),
        require('@/assets/image/chain/basic/aio/16_02.jpg'),
        require('@/assets/image/chain/basic/aio/16_03.jpg'),
        require('@/assets/image/chain/basic/aio/16_04.jpg'),
      ],
    };
  },
};
</script>

<style scoped>
</style>
