<template>
  <div style="width: 100%">
  <div v-for="item in datalist" :key="item.index" style="width: 33%;">
    <Lettertitle :title="item.title"
                 :imgsrc="item.imgsrc"></Lettertitle>
    <div>
      <ul style="list-style: none;margin-top: 2rem;margin-bottom: 6rem;
      padding: 0;">
        <li style="font-size: 1rem; line-height: 2rem;"
            v-for="citem in item.list" :key="citem.index">
          {{citem}}
        </li>
      </ul>
    </div>
  </div>
  </div>
</template>

<script>

import Lettertitle from '@/components/chain/basic/common/lettertitle';

export default {
  name: 'lettertitleblock',
  components: {
    Lettertitle,
  },
  props: ['datalist'],
};
</script>

<style scoped>

</style>
